<template>
	<div class="section_wrap pt-50 pb-80">
		<!-- 카르텔 카드 썸네일 리스트 -->
		<div class="card_thumbnail_wrap">
			<div class="container">
				<template
					v-if="items_cartel.length > 0"
				>
				<div class="row">
					<ul class="card_thumbnail_list">
						<li
							v-for="(item, index) in return_cartel"
							:key="'item_' + index"
						>
							<div class="card_thumbnail_item">
								<a @click="$emit('to', { name: 'mafia049', params: { idx: item.cartl_number, from: $route.name}})">
									<div class="thumbnail_area">
										<img
											v-if="item.cartl_img_url"
											:src="$request.upload_url(item.cartl_img_url)"
											:alt="item.cartl_name"
										/>
										<img
											v-else
											:src="require('@/assets/image/@noimage2.png')" :alt="$language.common.recommend_cartel">
										<div class="thumbnail_area_desc clear">
											<p class="left">{{ $language.notice.all_article }}<em>{{  item.all_articles_count }}</em></p>
											<p class="right">{{ $language.notice.new_article }}<em>{{  item.nwpost_count }}</em></p>
										</div>
									</div>
								</a>
								<div
									class="text_area"
								>
									<span class="name">{{  item.cartl_name }}</span>
									<button
										v-if="item.cartl_entry_state_code == ''"
										@click="$emit('to', { name: 'mafia049', params: { idx: item.cartl_number, from: $route.name}})"
										class="thumbnail_btn_m st_wjoin"
									>가입하기</button>
									<button
										v-else
										class="thumbnail_btn_m st_wjoin"
									>{{ item.cartl_entry_state_name }}</button>
								</div>
							</div>
						</li>
					</ul>
				</div>
				</template>
				<div
					v-else
					class="small_thumbnail_flex"
				><!-- 해당 페이지 높이값 지정 -->
					<div class="none_top character3_none">
						<div class="text_guide">
							<span class="cont_none">{{ $language.cartel.NoRegisteredRecommendCartel }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- //카르텔 카드 썸네일 리스트 -->
	</div>
</template>

<script>
	export default {
		name: 'mafia046'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: this.$language.cartel.recommend_cartel
					, title: this.$language.cartel.recommend_cartel
					, type: 'cartel_sub'
					, from: 'main'
				}
				, items_cartel: []
			}
		}
		, computed: {
			return_cartel: function(){
				let self = this
				return this.items_cartel.filter(function(item){
					if(item.user){
						if(item.user.indexOf(self.user.id) > -1){
							item.is_mine = true
						}
					}
					return item
				})
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_recomm_list
						, data: {
							member_number: this.user.member_number
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_cartel = result.data.recom_cartl_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toJoin: function(){
				this.$emit('to', { name: 'mafia049'})
			}
		}
		, created() {

			this.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>